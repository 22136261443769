<template>
  <div class="row">
    <div class="col-12" style="text-align: right; padding: 10px">
      <q-btn-group outline >
        <c-btn
          v-if="editable"
          :url="saveUrl"
          :isSubmit="isSave"
          :param="ghsResult"
          mappingType="PUT"
          label="저장"
          icon="save"
          @beforeAction="saveInfo"
          @btnCallback="saveCallback" />
      </q-btn-group>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-table
        ref="table"
        title="유해위험성"
        :merge="grid.merge"
        :columns="grid.columns"
        :data="ghsResult.classes"
        @innerBtnClicked="innerBtnClicked"
      >
        <template slot="table-button">
          <q-btn-group outline >
            <c-btn label="" icon="help_outline">
              <template slot="tooltip">
                <q-popup-proxy>
                  <c-table
                    title="유해위험성 구분 범례"
                    :columns="gridClass.columns"
                    :data="gridClass.data"
                    :gridHeight="gridClass.height"
                    hideBottom
                  >
                  </c-table>
                </q-popup-proxy>
              </template>
            </c-btn>
            <c-btn 
              v-if="editable" 
              label="분류 추가" 
              icon="add" 
              @btnClicked="addHarmfulRiskClass" />
          </q-btn-group>
        </template>
        <template v-slot:customArea="{ props, col }">
          <template v-if="col.name==='harmfulRiskResultCd'">
            <c-select
              stype="tableselect"
              :editable="editable"
              :isSelect="true"
              :codeGroupCd="setResultGroupCd(props)"
              type="edit"
              itemText="codeName"
              itemValue="code"
              v-model="props.row[col.name]"
              @datachange="val => datachange(props)" />
          </template>
          <template v-else-if="col.name==='delete'">
            <q-btn 
              dense
              outline 
              icon="delete_forever"
              color="red-7"
              size="12px"
              style="font-weight:700"
              @click="deleteHarmRiskClass(props.row)" />
          </template>
        </template>
      </c-table>
      <!-- <c-card title="유해위험성" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="2"
              name="classficationResult"
              v-model="ghsResult.classficationResult">
            </c-textarea>
          </div>
        </template>
      </c-card> -->
    </div>
    <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
      <c-card title="신호어" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="2"
              name="sign"
              v-model="ghsResult.sign">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-card title="그림문자" class="cardClassDetailForm picCard" topClass="topcolor-orange">
        <template slot="card-detail">
          <q-intersection
            v-for="(item, index) in ghsResult.pics"
            :key="index"
            class="example-item"
            style="display:inline-table;"
          >
            <template v-if="item.pic !== '해당없음'">
              <img :src="require('@/assets/images/' + item.pic + '.gif')"
                :style="{ width: '50px', }">
              <q-checkbox v-model="item.chk" 
                color="red"
                true-value="Y"
                false-value="N" />
            </template>
          </q-intersection>
        </template>
      </c-card>
      <c-card title="예방조치문구 (최초 3칸까지는 출력물에 표시됩니다.)" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="2"
              name="sign"
              v-model="ghsResult.pcodeResult">
            </c-textarea>
          </div>
        </template>
      </c-card>
      <c-card title="유해·위험문구" class="cardClassDetailForm" topClass="topcolor-orange">
        <template slot="card-detail">
          <div class="col-12 ghsinfodetail">
            <c-textarea
              :editable="editable"
              :rows="2"
              name="sign"
              v-model="ghsResult.hcodeResult">
            </c-textarea>
          </div>
        </template>
      </c-card>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-ghs-2',
  props: {
    param: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        plantCd: '',
        plantName: '',
        materialCd: '',
        materialName: '',
        productCategoryCd: '',
        isSaved: '',
        updateFlag: '',
      }),
    },
  },
  data() {
    return {
      harmRiskItems: [],
      ghsResult: {
        // classficationResult: '',
        classes: [],
        deleteClasses: [],
        pics: [],
        sign: '',
        pcodeResult: '',
        hcodeResult: '',
        regUserId: '',
        chgUserId: '',
      },
      grid: {
        merge: [
          { index: 0, colName: 'harmfulRiskClassCd', }
        ],
        columns: [
          {
            name: 'harmfulRiskClassName',
            field: 'harmfulRiskClassName',
            label: '분류',
            align: 'center',
            style: 'width:150px',
            sortable: true,
            innerBtn: true,
            btns: [
              { label: '', icon: 'add', color: 'light-blue', value: '1', tooltip: '적용 구분 추가' },
            ],
          },
          {
            name: 'harmfulRiskResultCd',
            field: 'harmfulRiskResultCd',
            label: '적용 구분',
            align: 'center',
            style: 'width:100px',
            sortable: true,
            required: true,
            type: 'custom',
          },
          {
            name: 'harmfulRiskDetails',
            field: 'harmfulRiskDetails',
            label: '구분 부가설명',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'text',
          },
          {
            name: 'delete',
            field: 'delete',
            label: '삭제',
            align: 'center',
            style: 'width:50px',
            sortable: true,
            type: 'custom',
          },
        ],
      },
      gridClass: {
        columns: [
          {
            fix: true,
            name: 'harmfulRiskClassName',
            field: 'harmfulRiskClassName',
            label: '유해위험성구분',
            align: 'center',
            style: 'width:130px',
            sortable: true,
          },
          {
            name: 'gubun1',
            field: 'gubun1',
            label: '구분1',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun1a',
            field: 'gubun1a',
            label: '구분1A',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun1b',
            field: 'gubun1b',
            label: '구분1B',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun1c',
            field: 'gubun1c',
            label: '구분1C',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun2',
            field: 'gubun2',
            label: '구분2',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun2a',
            field: 'gubun2a',
            label: '구분2A',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun2b',
            field: 'gubun2b',
            label: '구분2B',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun3',
            field: 'gubun3',
            label: '구분',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun4',
            field: 'gubun4',
            label: '구분',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'gubun5',
            field: 'gubun5',
            label: '구분',
            align: 'left',
            style: 'width:250px',
            sortable: true,
            type: 'html'
          },
        ],
        data: [],
        height: '500px'
      },
      editable: true,
      isSave: false,
      listUrl: '',
      listLegendUrl: '',
      saveUrl: '',
      deleteClassUrl: '',
      popupOptions: {
        isFull: false,
        width: '40%',
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.mdm.ghs.mix.list;
      this.listLegendUrl = selectConfig.mdm.mam.chem.harmfulLegendList.url;
      this.saveUrl = transactionConfig.mdm.ghs.mix.save;
      this.deleteClassUrl = transactionConfig.mdm.ghs.class.delete;
      // code setting
      // list setting
      this.getLegend();
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {mdmChemMaterialId: this.param.mdmChemMaterialId};
      this.$http.request((_result) => {
        this.$_.extend(this.ghsResult, _result.data)
      },);
    },
    getLegend() {
      this.$http.url = this.listLegendUrl;
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.gridClass.data = _result.data;
      },);
    },
    innerBtnClicked(col, props) {
      this.ghsResult.classes.splice(props.rowIndex, 0, {
        mdmChemMaterialId: this.param.mdmChemMaterialId,  // 화학자재 일련번호
        harmfulRiskClassCd: props.row.harmfulRiskClassCd,  // 유해위험성 구분 코드
        harmfulRiskClassName: props.row.harmfulRiskClassName,  // 유해위험성 구분 코드
        harmfulRiskResultCdSave: null,  // 유해위험성 결과 코드
        harmfulRiskResultCd: null,  // 유해위험성 결과 코드
        harmfulRiskDetails: '',  // 유해위험성 상세 설명
        regUserId: this.$store.getters.user.userId,  // 등록자 ID
        editFlag: 'C'
      });

    },
    addHarmfulRiskClass() {
      this.popupOptions.title = '분류 검색';
      this.popupOptions.param = {
        type: 'multiple'
      };
      this.popupOptions.target = () => import(`${'./ghsHarmfulRiskClassPop.vue'}`);
      this.popupOptions.width = '40%';
      this.popupOptions.isFull = false;
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeHarmfulRiskClassPopup;
    },
    closeHarmfulRiskClassPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let idx = this.$_.findIndex(this.ghsResult.classes, { harmfulRiskClassCd: item.code })
          idx = idx > -1 ? idx : 0
          this.ghsResult.classes.splice(idx, 0, { 
            mdmChemMaterialId: this.param.mdmChemMaterialId,  // 화학자재 일련번호
            harmfulRiskClassCd: item.code,  // 유해위험성 구분 코드
            harmfulRiskClassName: item.codeName,  // 유해위험성 구분 코드
            harmfulRiskResultCd: null,  // 유해위험성 결과 코드
            harmfulRiskDetails: '',  // 유해위험성 상세 설명
            regUserId: this.$store.getters.user.userId,  // 등록자 ID
            editFlag: 'C'
          })
        })
      }
    },
    deleteHarmRiskClass(row) {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          if (row.editFlag === 'C') {
            this.ghsResult.classes = this.$_.reject(this.ghsResult.classes, row)
            return;
          }
          this.$http.url = this.$format(this.deleteClassUrl, this.param.mdmChemMaterialId, row.harmfulRiskClassCd, row.harmfulRiskResultCdSave);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            this.ghsResult.classes = this.$_.reject(this.ghsResult.classes, row)
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    setResultGroupCd(props) {
      let codeGroupCd = ''
      switch(props.row['harmfulRiskClassCd']) {
        case 'HRC0000001': // 급성독성(경구)
        case 'HRC0000002': // 급성독성(경피)
        case 'HRC0000003': // 급성독성(흡입)
          codeGroupCd = 'ACUTE_TOXICITY_CD';
          break;
        case 'HRC0000004': // 피부 부식성
        case 'HRC0000005': // 피부 자극성
          codeGroupCd = 'SKIN_CORROSION_IRRITATION_CD';
          break;
        case 'HRC0000006': // 심한 눈 손상성 또는 자극성
          codeGroupCd = 'SERIOUS_EYE_DAMAGE_IRRITATION_CD';
          break;
        case 'HRC0000007': // 호흡기 과민성
          codeGroupCd = 'RESPIRATORY_SENSITIZATION_CD';
          break;
        case 'HRC0000008': // 피부 과민성
          codeGroupCd = 'SKIN_SENSITIZATION_CD';
          break;
        case 'HRC0000009': // 발암성
          codeGroupCd = 'CARCINOGENICITY_CD';
          break;
        case 'HRC0000010': // 생식세포 변이원성
          codeGroupCd = 'GERM_CELL_MUTAGENICITY_CD';
          break;
        case 'HRC0000011': // 생식독성
          codeGroupCd = 'REPRODUCTIVE_TOXICITY_CD';
          break;
        case 'HRC0000012': // 특정표적장기 독성(1회 노출)
          codeGroupCd = 'SPEC_TARGET_ORGAN_TOXICITY_SINGLE_EXPOSURE_CD';
          break;
        case 'HRC0000013': // 특정표적장기 독성(반복 노출)
          codeGroupCd = 'SPEC_TARGET_ORGAN_TOXICITY_REPEATED_EXPOSURE_CD';
          break;
        case 'HRC0000014': // 흡인유해성
          codeGroupCd = 'ASPIRATION_HAZARD_CD';
          break;
        default:
          codeGroupCd = '';
          break;
      }
      return codeGroupCd;
    },
    datachange(props) {
      /**
       * 적용 구분값이 변경될 시 해당 값은 삭제 처리
       * 변경된 값은 신규등록으로 처리
       */
      if (props.row['editFlag'] !== 'C') {
        if (!this.ghsResult.deleteClasses) this.ghsResult.deleteClasses = [];
        if (this.$_.findIndex(this.ghsResult.deleteClasses, { harmfulRiskClassCd: props.row.harmfulRiskClassCd, harmfulRiskResultCd: props.row.harmfulRiskResultCdSave }) === -1) {
          let deleteItem = this.$_.clone(props.row)
          deleteItem.harmfulRiskResultCd = deleteItem.harmfulRiskResultCdSave
          this.ghsResult.deleteClasses.push(deleteItem)
        }
        props.row['harmfulRiskResultCdSave'] = props.row.harmfulRiskResultCd
        props.row['editFlag'] = 'C'
        props.row['regUserId'] = this.$store.getters.user.userId
      }
    },
    saveInfo() {
      /**
       * 유해/위해성 중 동일한 정보가 들어있는지 검증
       */
      let isValid = true;
      if (this.ghsResult.classes && this.ghsResult.classes.length > 0) {
        let uniqClasses = this.$_.uniq(this.$_.map(this.ghsResult.classes, 'harmfulRiskClassCd'))
        this.$_.forEach(uniqClasses, uniqClass => {
          let filterData = this.$_.filter(this.ghsResult.classes, { harmfulRiskClassCd: uniqClass });
          if (filterData.length > 1 && this.$_.findIndex(filterData, (filterItem) => {
            return this.$_.filter(filterData, { harmfulRiskResultCd: filterItem.harmfulRiskResultCd }).length > 1
          }) > -1) {
            isValid = false;
            return false;
          }
        })
      }
      if (!isValid) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '[유해위해성] 분류, 적용 구분값이 동일한 데이터가 존재합니다.\n다시 입력하시기 바랍니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      }
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '저장하시겠습니까?',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.ghsResult.regUserId = this.$store.getters.user.userId;
          this.ghsResult.chgUserId = this.$store.getters.user.userId;

          this.isSave = !this.isSave;
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
  }
};
</script>

<style lang="sass">
  .ghsinfodetail
    padding-bottom:10px !important
    margin-top:-20px
  .picCard
    .customCardbody 
      padding: 10px !important
      margin: 0 !important
</style>